const popupClose = (button) => {
  const body = document.querySelector('body');

  button.addEventListener('click', (e) => {
    const isOverlay = e.target.classList.contains('j-overlay');
    const isButtonClose = button.classList.contains('j-popup__close');
    const popups = document.querySelectorAll('.popup_active');

    if (isOverlay || isButtonClose) {
      popups.forEach((item) => item.classList.remove('popup_active'));
      body.classList.remove('scroll-locked');
    }
  });
};

export default popupClose;
